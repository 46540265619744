<template>
	<main>
		<HeaderTab :title="$t('global.recherche_avancee')" :return_action="'navigationBack'"/>
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
                            <b-input-group class="mb-2">
                                <b-input type="text" class="col" v-model="local_search" @keydown.enter.prevent="search"/>
                                <b-input-group-append>
                                    <b-button @click="search">{{ $t("global.rechercher") }}</b-button>
                                </b-input-group-append>
                            </b-input-group>
                            <div class="row mb-2">
                                <div class="row col ml-1">
                                    <b-form-checkbox class="mr-2" v-model="horses">{{ $t('global.chevaux') }}</b-form-checkbox>
                                    <b-form-checkbox class="mr-2" v-model="tiers">{{ $t('tiers.tiers') }}</b-form-checkbox>
                                    <b-form-checkbox class="mr-2" v-model="contacts">{{ $t('contact.contacts') }}</b-form-checkbox>
                                    <b-form-checkbox class="mr-2" v-model="contracts">{{ $t('global.contracts') }}</b-form-checkbox>
                                    <b-form-checkbox v-model="invoices">{{ $t('horse.factures') }}</b-form-checkbox>
                                </div>
                                <div class="col-auto">
                                    <b-form-checkbox class="float-end" v-model="accurate_search" switch>{{ $t('global.accurate_search') }}</b-form-checkbox>
                                </div>
                            </div>
                            <div v-if="processing && !history">
                                <LoadingSpinner class="col-12" />
                            </div>
                            <div v-else-if="history">
                                <div v-if="horses && history_items.horses" class="mb-2">
                                    <h3>{{ $t('global.chevaux') }}</h3>
                                    <div v-if="!more_horses" class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="horse in history_items.horses" :key="horse.horse_id">
                                            <div class="card-body">
                                                <router-link v-if="horse.horse_inactive" @click.native="add_to_history('horses', horse.horse_id)" :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">
                                                    <strike> {{ horse.horse_nom }} </strike> 
                                                </router-link>
                                                <router-link v-else @click.native="add_to_history('horses', horse.horse_id)" :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">
                                                    {{ horse.horse_nom }}
                                                </router-link>
                                                <b-card-text>{{ horse.race_label }} | {{ horse.robe_label }} | {{ horse.sexe_label }}</b-card-text>

                                                <b-card-text>{{ horse.pedigree_nom_1 }} x {{ horse.pedigree_nom_2 }}</b-card-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="tiers && history_items.tiers" class="mb-2">
                                    <h3>{{ $t('tiers.tiers') }}</h3>
                                    <div v-if="!more_tiers" class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="tiers in history_items.tiers" :key="tiers.tiers_id">
                                            <div class="card-body">
                                                <router-link v-if="tiers.tiers_archive" @click.native="add_to_history('tiers', tiers.tiers_id)" :to="{ name: 'tiersFiche', params: { tiers_id: tiers.tiers_id }}">
                                                    <strike>{{ tiers.tiers_rs }}</strike>
                                                </router-link>
                                                <router-link v-else @click.native="add_to_history('tiers', tiers.tiers_id)" :to="{ name: 'tiersFiche', params: { tiers_id: tiers.tiers_id }}">
                                                    {{ tiers.tiers_rs }}
                                                </router-link>
                                                <b-card-text>
                                                    {{ tiers.tiers_address1 }}<br>
                                                    {{ tiers.tiers_address2 }}<br>
                                                    {{ tiers.tiers_postalcode }}<br>
                                                    {{ tiers.tiers_town }}<br>
                                                    <e-copybox classes="mb-3" mail :value="tiers.tiers_mail"></e-copybox>                                             
                                                    <e-copybox classes="mb-3" phone :value="tiers.phone_indicatif + tiers.phone_numero"></e-copybox>                                                </b-card-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="contacts && history_items.contacts" class="mb-2">
                                    <h3>{{ $t('contact.contacts') }}</h3>
                                    <div v-if="!more_contacts" class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="contact in history_items.contacts" :key="contact.contact_id">
                                            <div class="card-body">
                                                <router-link @click.native="add_to_history('contacts', contact.contact_id)" :to="{ name: 'contactCoordonnees', params: { contact_id: contact.contact_id }}">
                                                    {{ contact.contact_firstname }} {{ contact.contact_lastname }}
                                                </router-link>
                                                <b-card-text>
                                                    {{ contact.contact_address1 }}<br>
                                                    {{ contact.contact_address2 }}<br>
                                                    {{ contact.contact_postalcode }}<br>
                                                    {{ contact.contact_town }}<br>
                                                    {{ contact.contact_mail }}<br>
                                                    <span v-if="contact.horse && contact.horse.length != 0">{{ $t('advanced_search.link_horses') }}:</span>
                                                    <div v-for="h in contact.horse" :key="h.horse_id">
                                                        <router-link @click.native="add_to_history('horses', h.horse_id)" :to="{ name: 'horseFiche', params: { horse_id: h.horse_id }}">
                                                            {{ h.horse_nom }}
                                                        </router-link>
                                                    </div>
                                                    <span v-if="contact.tiers && contact.tiers.length != 0">{{ $t('advanced_search.link_tiers') }}:</span>
                                                    <div v-for="t in contact.tiers" :key="t.tiers_id">
                                                        <router-link @click.native="add_to_history('tiers', t.tiers_id)" :to="{ name: 'tiersFiche', params: { tiers_id: t.tiers_id }}">
                                                            {{ t.tiers_rs }}
                                                        </router-link>
                                                    </div>
                                                </b-card-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="contracts && history_items.contracts" class="mb-2">
                                    <h3>{{ $t('global.contracts') }}</h3>
                                    <div v-if="!more_contracts" class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="contract in history_items.contracts" :key="contract.contract_id">
                                            <div class="card-body">
                                                <router-link @click.native="add_to_history('contracts', contract.contract_id)" :to="{ name: 'ContractFormv2', params: { contract_id: contract.contract_id }}">
                                                    {{ contract.contract_num }}
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div v-if="invoices && history_items.invoices" class="mb-2">
                                    <h3>{{ $t('horse.factures') }}</h3>
                                    <div v-if="!more_invoices" class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="invoice in history_items.invoices" :key="invoice.invoice_id">
                                            <div class="card-body">
                                                <div class="row justify-content-between">
                                                    <router-link @click.native="add_to_history('tiers', invoice.tier.tiers_id)" :to="{ name: 'tiersFiche', params: { tiers_id: invoice.tier.tiers_id }}">
                                                        {{ invoice.tier.tiers_rs }}
                                                    </router-link>
                                                    <a href="" class="" @click.prevent="quickPreview(invoice.invoice_id)">
                                                        <font-awesome-icon :icon="['fas', 'eye']" />
                                                    </a>
                                                </div>
                                                <div class="row justify-content-between my-2">
                                                    <!-- <small>{{ $t('invoice.invoicedetails_ttc') }}: {{ invoice.invoice_ttc}}</small><br> -->
                                                    <small>{{ $t('invoice.invoice_balance') }}: {{invoice.invoice_balance }}</small>
                                                </div>
                                                <div class="row d-flex justify-content-between">
                                                    <b-button size="sm" variant="secondary" class="my-1" @click="downloadPdf(invoice.invoice_id)">
                                                        <font-awesome-icon :icon="['far', 'file-pdf']"></font-awesome-icon>
                                                        <span class="d-md-none d-lg-inline ml-1">{{ $t('action.imprimer') }}</span>
                                                    </b-button>
                                                    <b-button size="sm" variant="secondary" class="my-1" @click="openModalSendInvoice(invoice.invoice_id.toString(), invoice.invoice_author)">
                                                        <font-awesome-icon :icon="['far', 'paper-plane']"></font-awesome-icon>
                                                        <span class="d-md-none d-lg-inline ml-1">{{ $t('action.envoyer') }}</span>
                                                    </b-button>
                                                    <b-button size="sm" variant="secondary" class="my-1" @click="checkPaymentFormFunction(invoice)">
                                                        <font-awesome-icon :icon="['far', 'money-bill-alt']"></font-awesome-icon>
                                                        <span class="d-md-none d-lg-inline ml-1">{{ $t('action.reglement') }}</span>
                                                    </b-button>
                                                    <b-button size="sm" variant="secondary" class="my-1" @click="modalFactureAvoir(invoice)">
                                                    <font-awesome-icon :icon="['far', 'pencil-alt']"></font-awesome-icon>
                                                    <span class="d-md-none d-lg-inline ml-1">{{ $t('action.only_avoir') }}</span>
                                                    </b-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div v-else>
                                <div v-if="horses && items.horses" class="mb-2">
                                    <h3>{{ $t('global.chevaux') }}</h3>
                                    <div v-if="!more_horses" class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="horse in items.horses.slice(0,3)" :key="horse.horse_id">
                                            <div class="card-body">
                                                <router-link v-if="horse.horse_inactive" @click.native="add_to_history('horses', horse.horse_id)" :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">
                                                    <strike> {{ horse.horse_nom }} </strike> 
                                                </router-link>
                                                <router-link v-else @click.native="add_to_history('horses', horse.horse_id)" :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">
                                                    {{ horse.horse_nom }}
                                                </router-link>
                                                <b-card-text>{{ horse.race_label }} | {{ horse.robe_label }} | {{ horse.sexe_label }}</b-card-text>

                                                <b-card-text>{{ horse.pedigree_nom_1 }} x {{ horse.pedigree_nom_2 }}</b-card-text>
                                            </div>
                                        </div>
                                        <div v-if="items.horses.length > 3" class="card col-sm-3 col-md-3 col-lg-3">
                                            <div class="card-body">
                                                <b-card-text @click="display_more_horses">{{ items.horses.length - 3 }} {{ $t('advanced_search.other_results') }}</b-card-text>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="horse in items.horses" :key="horse.horse_id">
                                            <div class="card-body">
                                                <router-link v-if="horse.horse_inactive" @click.native="add_to_history('horses', horse.horse_id)" :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">
                                                    <strike> {{ horse.horse_nom }} </strike>                                                </router-link>
                                                <router-link v-else @click.native="add_to_history('horses', horse.horse_id)" :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">
                                                    {{ horse.horse_nom }}
                                                </router-link>
                                                <b-card-text>{{ horse.race_label }} | {{ horse.robe_label }} | {{ horse.sexe_label }}</b-card-text>

                                                <b-card-text>{{ horse.pedigree_nom_1 }} x {{ horse.pedigree_nom_2 }}</b-card-text>
                                            </div>
                                        </div>
                                        <div class="card col-sm-3 col-md-3 col-lg-3">
                                            <div class="card-body">
                                                <b-card-text @click="display_more_horses">{{ $t('advanced_search.less_results') }}</b-card-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="tiers && items.tiers" class="mb-2">
                                    <h3>{{ $t('tiers.tiers') }}</h3>
                                    <div v-if="!more_tiers" class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="tiers in items.tiers.slice(0,3)" :key="tiers.tiers_id">
                                            <div class="card-body">
                                                <router-link v-if="tiers.tiers_archive" @click.native="add_to_history('tiers', tiers.tiers_id)" :to="{ name: 'tiersFiche', params: { tiers_id: tiers.tiers_id }}">
                                                    <strike>{{ tiers.tiers_rs }}</strike> 
                                                </router-link>
                                                <router-link v-else @click.native="add_to_history('tiers', tiers.tiers_id)" :to="{ name: 'tiersFiche', params: { tiers_id: tiers.tiers_id }}">
                                                    {{ tiers.tiers_rs }}
                                                </router-link>
                                                <b-card-text>
                                                    {{ tiers.tiers_address1 }}<br>
                                                    {{ tiers.tiers_address2 }}<br>
                                                    {{ tiers.tiers_postalcode }}<br>
                                                    {{ tiers.tiers_town }}<br>
                                                    <e-copybox classes="mb-3" mail :value="tiers.tiers_mail"></e-copybox>                                             
                                                    <e-copybox classes="mb-3" phone :value="tiers.phone_indicatif + tiers.phone_numero"></e-copybox>
                                                </b-card-text>                                              
                                            </div>
                                        </div>
                                        <div v-if="items.tiers.length > 3" class="card col-sm-3 col-md-3 col-lg-3">
                                            <div class="card-body">
                                                <b-card-text @click="display_more_tiers">{{ items.tiers.length - 3 }} {{ $t('advanced_search.other_results') }}</b-card-text>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="tiers in items.tiers" :key="tiers.tiers_id">
                                            <div class="card-body">
                                                <router-link v-if="tiers.tiers_archive" @click.native="add_to_history('tiers', tiers.tiers_id)" :to="{ name: 'tiersFiche', params: { tiers_id: tiers.tiers_id }}">
                                                    <strike>{{ tiers.tiers_rs }}</strike> 
                                                </router-link>
                                                <router-link v-else @click.native="add_to_history('tiers', tiers.tiers_id)" :to="{ name: 'tiersFiche', params: { tiers_id: tiers.tiers_id }}">
                                                    {{ tiers.tiers_rs }}
                                                </router-link>
                                                <b-card-text>
                                                    {{ tiers.tiers_address1 }}<br>
                                                    {{ tiers.tiers_address2 }}<br>
                                                    {{ tiers.tiers_postalcode }}<br>
                                                    {{ tiers.tiers_town }}<br>
                                                    <e-copybox classes="mb-3" mail :value="tiers.tiers_mail"></e-copybox>                                             
                                                    <e-copybox classes="mb-3" phone :value="tiers.phone_indicatif + tiers.phone_numero"></e-copybox>
                                                </b-card-text>
                                            </div>
                                        </div>
                                        <div class="card col-sm-3 col-md-3 col-lg-3">
                                            <div class="card-body">
                                                <b-card-text @click="display_more_tiers">{{ $t('advanced_search.less_results') }}</b-card-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="contacts && items.contacts" class="mb-2">
                                    <h3>{{ $t('contact.contacts') }}</h3>
                                    <div v-if="!more_contacts" class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="contact in items.contacts.slice(0,3)" :key="contact.contact_id">
                                            <div class="card-body">
                                                <router-link @click.native="add_to_history('contacts', contact.contact_id)" :to="{ name: 'contactCoordonnees', params: { contact_id: contact.contact_id }}">
                                                    {{ contact.contact_firstname }} {{ contact.contact_lastname }}
                                                </router-link>
                                                <b-card-text>
                                                    {{ contact.contact_address1 }}<br>
                                                    {{ contact.contact_address2 }}<br>
                                                    {{ contact.contact_postalcode }}<br>
                                                    {{ contact.contact_town }}<br>
                                                    {{ contact.contact_mail }}
                                                    <span v-if="contact.horse && contact.horse.length != 0">{{ $t('advanced_search.link_horses') }}:</span>
                                                    <div v-for="h in contact.horse" :key="h.horse_id">
                                                        <router-link @click.native="add_to_history('horses', h.horse_id)" :to="{ name: 'horseFiche', params: { horse_id: h.horse_id }}">
                                                            {{ h.horse_nom }}
                                                        </router-link>
                                                    </div>
                                                    <span v-if="contact.tiers && contact.tiers.length != 0">{{ $t('advanced_search.link_tiers') }}:</span>
                                                    <div v-for="t in contact.tiers" :key="t.tiers_id">
                                                        <router-link @click.native="add_to_history('tiers', t.tiers_id)" :to="{ name: 'tiersFiche', params: { tiers_id: t.tiers_id }}">
                                                            {{ t.tiers_rs }}
                                                        </router-link>
                                                    </div>
                                                </b-card-text>
                                            </div>
                                        </div>
                                        <div v-if="items.contacts.length > 3" class="card col-sm-3 col-md-3 col-lg-3">
                                            <div class="card-body">
                                                <b-card-text @click="display_more_contacts">{{ items.contacts.length - 3 }} {{ $t('advanced_search.other_results') }}</b-card-text>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="contact in items.contacts" :key="contact.contact_id">
                                            <div class="card-body">
                                                <router-link @click.native="add_to_history('contacts', contact.contact_id)" :to="{ name: 'contactCoordonnees', params: { contact_id: contact.contact_id }}">
                                                    {{ contact.contact_firstname }} {{ contact.contact_lastname }}
                                                </router-link>
                                                <b-card-text>
                                                    {{ contact.contact_address1 }}<br>
                                                    {{ contact.contact_address2 }}<br>
                                                    {{ contact.contact_postalcode }}<br>
                                                    {{ contact.contact_town }}<br>
                                                    {{ contact.contact_mail }}
                                                </b-card-text>
                                            </div>
                                        </div>
                                        <div class="card col-sm-3 col-md-3 col-lg-3">
                                            <div class="card-body">
                                                <b-card-text @click="display_more_contacts">{{ $t('advanced_search.less_results') }}</b-card-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="contracts && items.contracts" class="mb-2">
                                    <h3>{{ $t('global.contracts') }}</h3>
                                    <div v-if="!more_contracts" class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="contract in items.contracts.slice(0,3)" :key="contract.contract_id">
                                            <div class="card-body">
                                                <router-link @click.native="add_to_history('contracts', contract.contract_id)" :to="{ name: 'ContractFormv2', params: { contract_id: contract.contract_id }}">
                                                    {{ contract.contract_num }}
                                                </router-link>
                                            </div>
                                        </div>
                                        <div v-if="items.contracts.length > 3" class="card col-sm-3 col-md-3 col-lg-3">
                                            <div class="card-body">
                                                <b-card-text @click="display_more_contracts">{{ items.contracts.length - 3 }} {{ $t('advanced_search.other_results') }}</b-card-text>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="contract in items.contracts" :key="contract.contract_id">
                                            <div class="card-body">
                                                <router-link @click.native="add_to_history('contracts', contract.contract_id)" :to="{ name: 'ContractFormv2', params: { contract_id: contract.contract_id }}">
                                                    {{ contract.contract_num }}
                                                </router-link>
                                            </div>
                                        </div>
                                        <div class="card col-sm-3 col-md-3 col-lg-3">
                                            <div class="card-body">
                                                <b-card-text @click="display_more_contracts">{{ $t('advanced_search.less_results') }}</b-card-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="invoices && items.invoices" class="mb-2">
                                    <h3>{{ $t('horse.factures') }}</h3>
                                    <div v-if="!more_invoices" class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="invoice in items.invoices.slice(0,3)" :key="invoice.invoice_id">
                                            <div class="card-body">
                                                <div class="row justify-content-between">
                                                    <router-link @click.native="add_to_history('tiers', invoice.tier.tiers_id)" :to="{ name: 'tiersFiche', params: { tiers_id: invoice.tier.tiers_id }}">
                                                        {{ invoice.tier.tiers_rs }}
                                                    </router-link>
                                                    <a href="" class="" @click.prevent="quickPreview(invoice.invoice_id)">
                                                        <font-awesome-icon :icon="['fas', 'eye']" />
                                                    </a>
                                                </div>
                                                <div class="row justify-content-between my-2">
                                                    <!-- <small>{{ $t('invoice.invoicedetails_ttc') }}: {{ invoice.invoice_ttc}}</small><br> -->
                                                    <small>{{ $t('invoice.invoice_balance') }}: {{invoice.invoice_balance }}</small>
                                                </div>
                                                <div class="row d-flex justify-content-around">
                                                    <b-button size="sm" variant="secondary" class="my-1" @click="downloadPdf(invoice.invoice_id)">
                                                        <font-awesome-icon :icon="['far', 'file-pdf']"></font-awesome-icon>
                                                        <span class="d-md-none d-lg-inline ml-1">{{ $t('action.imprimer') }}</span>
                                                    </b-button>
                                                    <b-button size="sm" variant="secondary" class="my-1" @click="openModalSendInvoice(invoice.invoice_id.toString(), invoice.invoice_author)">
                                                        <font-awesome-icon :icon="['far', 'paper-plane']"></font-awesome-icon>
                                                        <span class="d-md-none d-lg-inline ml-1">{{ $t('action.envoyer') }}</span>
                                                    </b-button>
                                                    <b-button size="sm" variant="secondary" class="my-1" @click="checkPaymentFormFunction(invoice)">
                                                        <font-awesome-icon :icon="['far', 'money-bill-alt']"></font-awesome-icon>
                                                        <span class="d-md-none d-lg-inline ml-1">{{ $t('action.reglement') }}</span>
                                                    </b-button>
                                                    <b-button size="sm" variant="secondary" class="my-1" @click="modalFactureAvoir(invoice)">
                                                    <font-awesome-icon :icon="['far', 'pencil-alt']"></font-awesome-icon>
                                                    <span class="d-md-none d-lg-inline ml-1">{{ $t('action.only_avoir') }}</span>
                                                    </b-button>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="items.invoices.length > 3" class="card col-sm-3 col-md-3 col-lg-3">
                                            <div class="card-body">
                                                <b-card-text @click="display_more_invoices">{{ items.invoices.length - 3 }} {{ $t('advanced_search.other_results') }}</b-card-text>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="row col-12">
                                        <div class="card col-sm-3 col-md-3 col-lg-3" v-for="invoice in items.invoices" :key="invoice.invoice_id">
                                            <div class="card-body">
                                                <div class="row justify-content-between">
                                                    <router-link @click.native="add_to_history('tiers', invoice.tier.tiers_id)" :to="{ name: 'tiersFiche', params: { tiers_id: invoice.tier.tiers_id }}">
                                                        {{ invoice.tier.tiers_rs }}
                                                    </router-link>
                                                    <a href="" class="" @click.prevent="quickPreview(invoice.invoice_id)">
                                                        <font-awesome-icon :icon="['fas', 'eye']" />
                                                    </a>
                                                </div>
                                                <div class="row justify-content-between my-2">
                                                    <!-- <small>{{ $t('invoice.invoicedetails_ttc') }}: {{ invoice.invoice_ttc}}</small><br> -->
                                                    <small>{{ $t('invoice.invoice_balance') }}: {{invoice.invoice_balance }}</small>
                                                </div>
                                                <div class="row d-flex justify-content-around">
                                                    <b-button size="sm" variant="secondary" class="my-1" @click="downloadPdf(invoice.invoice_id)">
                                                        <font-awesome-icon :icon="['far', 'file-pdf']"></font-awesome-icon>
                                                        <span class="d-md-none d-lg-inline ml-1">{{ $t('action.imprimer') }}</span>
                                                    </b-button>
                                                    <b-button size="sm" variant="secondary" class="my-1" @click="openModalSendInvoice(invoice.invoice_id.toString(), invoice.invoice_author)">
                                                        <font-awesome-icon :icon="['far', 'paper-plane']"></font-awesome-icon>
                                                        <span class="d-md-none d-lg-inline ml-1">{{ $t('action.envoyer') }}</span>
                                                    </b-button>
                                                    <b-button size="sm" variant="secondary" class="my-1" @click="checkPaymentFormFunction(invoice)">
                                                        <font-awesome-icon :icon="['far', 'money-bill-alt']"></font-awesome-icon>
                                                        <span class="d-md-none d-lg-inline ml-1">{{ $t('action.reglement') }}</span>
                                                    </b-button>
                                                    <b-button size="sm" variant="secondary" class="my-1" @click="modalFactureAvoir(invoice)">
                                                    <font-awesome-icon :icon="['far', 'pencil-alt']"></font-awesome-icon>
                                                    <span class="d-md-none d-lg-inline ml-1">{{ $t('action.only_avoir') }}</span>
                                                    </b-button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card col-sm-3 col-md-3 col-lg-3">
                                            <div class="card-body">
                                                <b-card-text @click="display_more_invoices">{{ $t('advanced_search.less_results') }}</b-card-text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>

        <b-modal ref="modalPayment" hide-footer size="xxl">
            <template v-slot:modal-title>
                    {{ $t("payment.add_payment") }}
            </template>

            <AddPayment ref="addPayment" :invoice_ids="invoice_ids" :invoice_accountingplans="invoice_accountingplans" :tiers_id="tiers_id" :invoice_nums="invoice_nums" :invoice_balances="invoice_balances" :processing.sync="processing" :ready.sync="ready" :submit_payment.sync="submit_payment"></AddPayment>

            <div class="d-none col-8 m-auto">
                <b-button v-if="ready" block pill variant="primary" @click.prevent="onSubmit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
            </div>
        </b-modal>

        <b-modal size="xl" ref="modalAvoir" hide-footer>
            <template v-slot:modal-title>
                    {{ $t("invoice.add_avoir") }}
            </template>
            <AddAvoir ref="addAvoir" :only_on_baf="only_on_baf" :facture_avoir="facture_avoir" :ready.sync="ready" :processing.sync="processing" :invoiceable.sync="invoiceable"></AddAvoir>
            <div class="col-4 m-auto">
                <b-button v-if="ready" block pill variant="primary" @click.prevent="submitAvoir"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.valider") }}</b-button>
                <b-button v-if="ready" block pill variant="primary" @click.prevent="submitAvoir(true)"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.valider_en_baf") }}</b-button>
            </div>
        </b-modal>

        <ModalSendInvoice ref="modal_send_invoice"/>

        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>
	</main>
</template>

<script type="text/javascript">
import Invoice from "@/mixins/Invoice.js"
import Tools from "@/mixins/Tools.js"
import Common from '@/assets/js/common.js'
import Payment from "@/mixins/Payment.js"

export default {
	name: "AdvancedSearch",
	mixins: [Invoice, Tools, Payment],
    props: ['search_value'],
	data () {
		return {
            invoice: null,
            invoice_accountingplans: null,
            invoice_ids: '',
            invoice_nums: null,
            invoice_balances: 0,
            submit_payment: false,
            ready: false,
            invoiceable: true,
            horses: true,
            accurate_search: false,
            facture_avoir: {},
            only_on_baf: false,
            tiers: true,
            tiers_id: 0,
            contacts: true,
            contracts: true,
            invoices: true,
            items: [],
            history_items: [],
            more_horses: false,
            more_tiers: false,
            more_contacts: false,
            more_contracts: false,
            more_invoices: false,
            local_search: null,
            processing: false,
            history: false,
            base64: null,
            search_params: this.getConfig('advanced_search_params'),
            actual_history: this.getConfig('advanced_search_history')
		}
	},
	mounted () {
		this.init_component()
	},
	methods: {
		async init_component() {
            this.local_search = this.search_value
            
            if(this.search_params) {
                this.horses = this.search_params.horses
                this.tiers = this.search_params.tiers
                this.contacts = this.search_params.contacts
                this.contracts = this.search_params.contracts
                this.invoices = this.search_params.invoices
            }

            if(this.local_search && this.local_search != '') {
                await this.search()
            }
		},
        async display_history() {
            this.history_items = await this.getAdvancedSearchHistory(this.actual_history)
            await this.formattedInvoice(this.history_items.invoices)
        },
        async search() {
            this.processing = true
            
            if(!this.search_params || (this.search_params.horses != this.horses || this.search_params.tiers != this.tiers || this.search_params.contacts != this.contacts
                || this.search_params.contracts != this.contracts || this.search_params.invoices != this.invoices || this.search_params.accurate_search != this.accurate_search)) {

                const params = {
                    horses: this.horses,
                    tiers: this.tiers,
                    contacts: this.contacts,
                    contracts: this.contracts,
                    invoices: this.invoices,
                    accurate_search: this.accurate_search
                }

                this.setConfig('advanced_search_params', params)
            }

            this.items = await this.getMathedResults(this.local_search, this.horses, this.tiers, this.contacts, this.contracts, this.invoices, this.accurate_search)
            if(this.items.invoices) {
                await this.formattedInvoice(this.items.invoices)
            }
            if(this.history) {
                this.history = false
            }
            this.processing = false
        },
        display_more_horses() {
            this.more_horses = !this.more_horses
        },
        display_more_tiers() {
            this.more_tiers = !this.more_tiers
        },
        display_more_contacts() {
            this.more_contacts = !this.more_contacts
        },
        display_more_contracts() {
            this.more_contracts = !this.more_contracts
        },
        display_more_invoices() {
            this.more_invoices = !this.more_invoices
        },
        add_to_history(type, id) {
            let new_history = {}
            if(this.actual_history) {
                new_history = this.actual_history
            }

            if(type === 'horses') {
                if(new_history.horses && !new_history.horses.find(el => el == id)) {
                    if(new_history.horses.length > 2) {
                        new_history.horses.shift()
                    }
                    new_history.horses.push(id)
                }
                else if(!new_history.horses) {
                    new_history.horses = [id]
                }
            }
            else if(type === 'tiers') {
                if(new_history.tiers && !new_history.tiers.find(el => el == id)) {
                    if(new_history.tiers.length > 2) {
                        new_history.tiers.shift()
                    }
                    new_history.tiers.push(id)
                }
                else if(!new_history.tiers) {
                    new_history.tiers = [id]
                }
            }
            else if(type === 'contacts') {
                if(new_history.contacts && !new_history.contacts.find(el => el == id)) {
                    if(new_history.contacts.length > 2) {
                        new_history.contacts.shift()
                    }
                    new_history.contacts.push(id)
                }
                else if(!new_history.contacts) {
                    new_history.contacts = [id]
                }
            }
            else if(type === 'contracts') {
                if(new_history.contracts && !new_history.contracts.find(el => el == id)) {
                    if(new_history.contracts.length > 2) {
                        new_history.contracts.shift()
                    }
                    new_history.contracts.push(id)
                }
                else if(!new_history.contracts) {
                    new_history.contracts = [id]
                }
            }
            else if(type === 'invoices') {
                if(new_history.invoices && !new_history.invoices.find(el => el == id)) {
                    if(new_history.invoices.length > 2) {
                        new_history.invoices.shift()
                    }
                    new_history.invoices.push(id)
                }
                else if(!new_history.invoices) {
                    new_history.invoices = [id]
                }
            }

            this.setConfig('advanced_search_history', new_history)
        },
        async quickPreview(invoice_id) {
            this.$refs.modelPreview.show()
            let retour = await this.pdfInvoice([invoice_id], "", true)
            if(retour) {
                this.base64 = retour
            }
            this.add_to_history('invoices', invoice_id)
        },
        async downloadPdf(invoice_ids) {
            const platform = Common.getActualPlatformEvo()

            if(platform != "regular" && Array.isArray(invoice_ids) && invoice_ids.length > 1) {
                this.failureToast('invoice.fonction_indispo_plateforme')
                return
            }

            if(platform != "regular") {
                const resultPdf = await this.downloadPdfInvoice(invoice_ids)
                const base64Cleanedup = this.formatBase64String(resultPdf)

                this.previewPdf(base64Cleanedup, () => {
                    // EventBus.$emit('TableAction::stopSpin')
                })
            }
            else {
                this.downloadPdfInvoice(invoice_ids).then(() => {
                    // EventBus.$emit('TableAction::stopSpin')
                })
            }
        },
        checkPaymentFormFunction(invoice){
            invoice.invoice_ids = invoice.invoice_id.toString()
            invoice.invoice_nums = invoice.invoice_num
            invoice.invoice_tiers = invoice.invoice_tiers.toString()
            invoice.invoice_accountingplans = invoice.invoice_accountingplan.toString()
            invoice.invoice_ttc = [invoice.invoice_ttc]

            this.tiers_id = 0
            if(this.checkPaymentForm(invoice))
            {
                this.$refs['modalPayment'].show()
            }
        },
        async onSubmit(){
            if(!this.processing)  {
                this.processing = true
                let return_payment = await this.$refs['addPayment'].addPayment()
                if(return_payment) {
                    this.$store.commit({ type: 'updateRefreshTabs', view: 'TiersFiche', tab: "coordonneesTiers", val: true })
                    this.$store.commit({ type: 'updateRefreshTabs', view: 'TiersFiche', tab: "Invoice", val: true })
                    this.$refs["modalPayment"].hide()
                    this.tiers_id = 0
                    await this.search()
                }
            }
        },
        openModalSendInvoice(invoice_ids, author_id) {
            this.$refs.modal_send_invoice.openModal(invoice_ids, author_id)
        },
        async modalFactureAvoir(invoice){
            invoice.tiers = invoice.tier
            this.facture_avoir = [invoice]
            this.$refs['modalAvoir'].show()
        },
        async submitAvoir(only_baf = false){
            this.only_on_baf = only_baf
            await this.$refs['addAvoir'].submit()
        },
        async formattedInvoice(invoices){
            for (const invoice of invoices){
                invoice.invoice_ttc = await this.priceFormat(invoice.invoice_ttc/100, invoice.author.tiers_currency, true)
                invoice.invoice_balance = await this.priceFormat(invoice.invoice_balance/100, invoice.author.tiers_currency, true)
            }
            return invoices
            
        },
	},
    watch: {
        local_search() {
            if(!this.local_search || this.local_search == '') {
                this.history = true
                this.display_history()
            }
        },
        submit_payment() {
            this.onSubmit()
        },
        search_value() {
            this.init_component()
        }
    },
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
        LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
        ModalSendInvoice : () => import('@/components/Tiers/ModalSendInvoice'),
        AddAvoir: () => import('@/components/Invoice/AddAvoir'),
        AddPayment: () => import('@/components/Payment/AddPayment')
	}
}
</script>